import { Header } from "../organism/Header";
import Home from "../pages/Home"

export default function DefaultLayout(){
    return(
        <>
        <Header/>
        <Home/>
        </>
    )
}