import { SectionContainer } from "../atoms/containers/ContentContainers";
import sectionFourBg from "../../assets/img/OurDesing-bg.png"
import { SectionFourContent } from "../molecules/section_four_content/SectionFourContent";



export function SectionFour(){
    return(
        <SectionContainer imgRoute={sectionFourBg} id={"four"}>
            <SectionFourContent/>
        </SectionContainer>
    )
}