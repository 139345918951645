import { SectionContainer } from "../atoms/containers/ContentContainers";
import sectionThree from "../../assets/img/WhyChoose-bg.png"
import { SectionTrheeContent } from "../molecules/section_trhee_content/SectionTrheeContent";



export function SectionThree(){
    return(
        <SectionContainer imgRoute={sectionThree} id={"three"}>
            <SectionTrheeContent/>
        </SectionContainer>
    )
}