import "./Types.css"

export function TypeH1({ children, className }) {
  return (
    <h1 className={`typeH1 ${className}`} >{children}</h1>
  )
}

export function TypeH2({ children, className }) {
  return (
    <h2 className={`typeH2 ${className}`} >{children}</h2>
  )
}

export function TypeH3({ children, className }) {
  return (
    <h3 className={`typeH3 ${className}`} >{children}</h3>
  )
}

export function TypeH4({ children, className }) {
  return (
    <h4 className={`typeH4 ${className}`} >{children}</h4>
  )
}

export function TypeH5({ children, className }) {
  return (
    <h5 className={`typeH5 ${className}`} >{children}</h5>
  )
}

export function TypeH6({ children, className }) {
  return (
    <h6 className={`typeH6 ${className}`} >{children}</h6>
  )
}

export function TypeSubtitle1({ children, className }) {
  return (
    <p className={`typeSubtitle1 ${className}`}>{children}</p>
  )
}

export function TypeSubtitle2({ children, className }) {
  return(
    <p className={`typeSubtitle2 ${className}`}>{children}</p>
  )
}


export function TypeBody1({ children, className }) {
  return (
    <p className={`typeBody1 ${className}`}>{children}</p>
  )
}

export function TypeBody2({ children, className }) {
  return (
    <p className={`typeBody2 ${className}`}>{children}</p>
  )
}

export function TypeButton({ children, className }) {
  return (
    <p className={`typeButton ${className}`}>{children}</p>
  
  )
}

export function TypeOverline({children, className}) {
  return (
    <p className={`typeOverline ${className}`}>{children}</p>
  )
}

export function TypeCaption({children, className}) {
  return (
    <p className={`typeCaption ${className}`}>{children}</p>
  )
}



