import { Footer } from "../organism/Footer";
import { Hero } from "../organism/Hero";
import { SectionFive } from "../organism/SectionFive";
import { SectionFour } from "../organism/SectionFour";
import { SectionOne } from "../organism/SectionOne";
import { SectionThree } from "../organism/SectionThree";
import { SectionTwo } from "../organism/SectionTwo";

export default function Home(){
    return(
        <>
         <Hero/>
         <SectionOne/>
         <SectionTwo/>
         <SectionThree/>
         <SectionFour/>
         <SectionFive/>
         <Footer/>
        </>
    )
}