import { SectionContainer } from "../atoms/containers/ContentContainers";
import sectionOneBg from "../../assets/img/about-us-bg.png"
import { SectionOneContent } from "../molecules/section_one_content/SectionOneContent";


export function SectionOne() {
    return(
        <SectionContainer imgRoute={sectionOneBg} id={"one"}>
            <SectionOneContent/>
        </SectionContainer>
    )
}