import { TypeButton } from "../../atoms/fonts/Types";
import "./MenuFooter.css"

export function MenuFooter() {
	return (
		<menu className="menuFooter">
			<a className={"Nav_link"} href="/">
			<TypeButton className={"-Nav_type"}>
				HOME
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="/">
			<TypeButton className={"-Nav_type"}>
				BIO
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="/">
			<TypeButton className={"-Nav_type"}>
				WORK
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="/">
			<TypeButton className={"-Nav_type"}>
				BLOG
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="/">
			<TypeButton className={"-Nav_type"}>
				HIRE
			</TypeButton>
			</a>
		</menu>
	)
}