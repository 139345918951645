import { useState, useEffect } from 'react';
import { HeaderContainer } from "../atoms/containers/ContentContainers";
import { IcoBrand } from "../atoms/icons/IcoBrand";
import { Nav } from "../molecules/nav/Nav"
import { MenuButton } from '../molecules/menu_button/MenuButton';

export function Header(){
    const [open, setOpen] = useState(false);
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    useEffect(() => {
        // Añadir o eliminar la clase 'scroll-lock' al cuerpo según el valor de 'open'
        if (open && isMobile) {
            document.body.classList.add('scroll-lock');
        } else {
            document.body.classList.remove('scroll-lock');
        }

        // Limpiar la clase al desmontar el componente
        return () => {
            document.body.classList.remove('scroll-lock');
        };
    }, [open]);


    useEffect(() => {
        // Establecer el estado 'open' basado en la condición 'isMobile'
        if (!isMobile) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isMobile]);

    return (
        <HeaderContainer>
            <a className={"Nav_link"} href="#hero">
            <IcoBrand className={"-w7"}/>
            </a>
            <Nav open={open} setOpen={setOpen} isMobile={isMobile}/>
            {isMobile && <MenuButton setOpen={setOpen}  open={open}/>}
        </HeaderContainer>
    )
}