import { TypeButton } from "../../fonts/Types";
import "./Button.css"


export function StandarButton({variant, icon, label}){

    const btnStyleSelector = (variant) => {
        switch (variant) {
            case 1:
                return  "btnPrimary";
            case 2:
                return  "btnSecondary";
            default:
                return "btnPrimary" ;
        }
    };

    let btnStyle = btnStyleSelector(variant);

    return(
        <button className={btnStyle}>
            {icon}<TypeButton>{label}</TypeButton>
        </button>
    )
} 