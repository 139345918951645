import { CardContainer } from "../../atoms/containers/ContentContainers";
import { TypeBody1, TypeH3, TypeH6 } from "../../atoms/fonts/Types";
import { IconPlus } from "../../atoms/icons/Icons";
import { StandarButton } from "../../atoms/util/buttons/Buttons";

import "./SectionOneContent.css"


export function SectionOneContent() {
    return (
        <div className="sectionOneContent" data-aos="fade-up" data-aos-duration="3000">
            <TypeH3 className={"-p900-50 "}>About Near Shore Design</TypeH3>
            <TypeBody1 className={"-s50"}>Near Shore Design is more than just a company; it's a creative powerhouse, a beacon of innovation, and a hub for development excellence. We specialize in designing and developing solutions that are not only cutting-edge but also strategically positioned to thrive in the ever-evolving digital landscape.</TypeBody1>
            <div className="sectionOneContent_CardsContainer" >
                <CardContainer mod={"-w-basis"}>
                    <TypeH6 className={"-p900-50 "}>
                        Our Design Philosophy:
                    </TypeH6>
                    <TypeBody1 className={"-s50"}>
                        At Near Shore Design, we believe that the foundation of any successful project lies in its design. Our team of seasoned experts meticulously crafts design solutions that are not only aesthetically pleasing but also user-centric and highly functional. We understand that design is not just about how something looks; it's about how it works and how it can elevate the user experience.
                    </TypeBody1>
                     {/*
                    <StandarButton
						icon={<IconPlus />}
						label={"EXPLORE"}
						variant={2} />
                    */}
                </CardContainer>
                <CardContainer mod={"-w-basis"}>
                    <TypeH6 className={"-p900-50 "}>
                        Factory of Development:
                    </TypeH6>
                    <TypeBody1 className={"-s50"}>
                        We take immense pride in being a "Factory of Development." What does this mean? It means that we are a dedicated and highly efficient production line for transforming ideas into fully-fledged digital products. We work tirelessly to streamline the development process, ensuring that your project is delivered on time, within budget, and to the highest quality standards.
                    </TypeBody1>
                    {/*
                    <StandarButton
						icon={<IconPlus />}
						label={"EXPLORE"}
						variant={2} />
                    */}
                </CardContainer>
            </div>
        </div>
    )
}