import { SectionContainer } from "../atoms/containers/ContentContainers";
import sectionTwoBg from "../../assets/img/dignity-bg.png"
import { SectionTwoContent } from "../molecules/section_two_content/SectionTwoContent";



export function SectionTwo(){
    return(
        <SectionContainer imgRoute={sectionTwoBg} id={"two"}>
            <SectionTwoContent/>
        </SectionContainer>
    )
}