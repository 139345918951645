import { FooterContainer } from "../atoms/containers/ContentContainers";
import { FooterContent } from "../molecules/footer_content/FooterContent";
import { MenuFooter } from "../molecules/menu_footer/MenuFooter";

export function Footer(){
    return(
        <FooterContainer>
            <MenuFooter/>
            <FooterContent/>
        </FooterContainer>
    )
}