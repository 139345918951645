import { TypeBody1, TypeH3 } from "../../atoms/fonts/Types";
import { IconPlus } from "../../atoms/icons/Icons";
import { StandarButton } from "../../atoms/util/buttons/Buttons";
import { PhoneVisor } from "../../atoms/util/mockup/Mockup";
import "./SectionFourContent.css"


export function SectionFourContent(){
    return(
        <div className="sectionFourContent" data-aos="fade-up" data-aos-duration="3000">
            <PhoneVisor/>
            <div className="sectionFourContent_text" >
                <TypeH3 className={"-p900-50"}>Our Design System</TypeH3>
                <TypeBody1 className={"-s50"}>
                One of our key assets at Near Shore Design is our proprietary design system. This system is the backbone of our creative process, ensuring consistency, scalability, and efficiency in every project we undertake. With our design system, we can quickly adapt to changing requirements, maintain brand identity, and accelerate the development process, ultimately saving you time and resources.

                </TypeBody1>
                 {/*}
                    <StandarButton
						icon={<IconPlus />}
						label={"EXPLORE"}
						variant={2} />
                    */}
            </div>
        </div>
    )
}