import { TypeBody1, TypeCaption, TypeOverline } from "../../atoms/fonts/Types";
import { IcoBrand } from "../../atoms/icons/IcoBrand";
import "./FooterContent.css"


export function FooterContent() {
	return (
		<div className="footerContent">
			<div className="footerContent_left">
				<div className="footerContent_contact">
					<div className="footerContent_info">
						<TypeCaption className={"-s50"}>Sign up</TypeCaption>
						<TypeBody1 className={"-s50"}>+1 (814) 441-6131</TypeBody1>
					</div>
					<div className="footerContent_info">
						<TypeCaption className={"-s50"}>Opening hours</TypeCaption>
						<TypeBody1 className={"-s50"}>24/7</TypeBody1>
					</div>
				</div>
				<div className="footerContent_info">
					<TypeCaption className={"-s50"}>Email</TypeCaption>
					<TypeBody1 className={"-s50"}>management@nearshoredesign.co</TypeBody1>
				</div>
			</div>
			<div className="footerContent_right">
				<IcoBrand className={"-w30"}/>
				<TypeOverline className={"-s50"}>Contact us today to discuss how we can help you achieve your digital goals and become a part of our growing family of satisfied clients.</TypeOverline>
				<TypeOverline className={"-s50"}>© 2023 — All rights reserved</TypeOverline>
				</div>
		</div>
	)
}
