import { TypeButton } from "../../atoms/fonts/Types";
import "./Nav.css"
import "../../animation/animation.css"


export function Nav({open,setOpen, isMobile}) {
	let defaultClass;
	switch (isMobile) {
		case true:
			defaultClass = `Nav ${open  ? "slide-in-elliptic-top-fwd" : "slide-out-elliptic-top-bck"}`;
			break;
		case false:
			defaultClass = "Nav slide-in-elliptic-top-fwd";
			break;
		default: defaultClass = "Nav slide-in-elliptic-top-fwd";
	}

	return (
		<nav className={defaultClass}>
			<a className={"Nav_link"} href="#hero" onClick={() => setOpen(!open)}>
			<TypeButton className={"-Nav_type"}>
				HOME
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="#one" onClick={() => setOpen(!open)}>
			<TypeButton className={"-Nav_type"}>
				About
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="#two" onClick={() => setOpen(!open)}>
			<TypeButton className={"-Nav_type"}>
				Work
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="#three" onClick={() => setOpen(!open)}>
			<TypeButton className={"-Nav_type"}>
				Qualities
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="#four" onClick={() => setOpen(!open)}>
			<TypeButton className={"-Nav_type"}>
				Design
			</TypeButton>
			</a>
			<a className={"Nav_link"} href="#five" onClick={() => setOpen(!open)}>
			<TypeButton className={"-Nav_type"}>
				Contact
			</TypeButton>
			</a>
		</nav>
	)
}