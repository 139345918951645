import "./ContentContainers.css"


export function HeaderContainer({ children }) {
	return (
		<header className="headerContainer">
			{children}
		</header>
	)

}

export function HeroContainer({ children, imgRoute, id }) {
	return (
		<main className="heroContainer" style={{ backgroundImage: `url(${imgRoute})` }} id={id}>
			<div className="heroContainer_content">
				{children}
			</div>

		</main>
	)
}

export function SectionContainer({ children, imgRoute, id }) {
	return (
		<section className="sectionContainer" style={{ backgroundImage: `url(${imgRoute})` }} id={id}>
			<div className="sectionContainer_content">
				{children}
			</div>
		</section>
	)

}

export function CardContainer({ children, mod }) {

	return (
		<div className={`cardContainer ${mod}`}>
			{children}
		</div>
	)
}

export function FooterContainer({ children }) {
	return (
		<footer className="footerContainer">
			{children}
		</footer>
	)
}