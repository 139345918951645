import "./Mockup.css"


export function PhoneVisor() {
	return (
		<div className="phoneVisor">


			<div className="phoneVisor_SVG">
				<svg width="329" height="662" viewBox="0 0 329 662" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g filter="url(#filter0_i_4_154)">
						<rect x="3" width="323" height="662" rx="62" fill="#1A1A1A" />
					</g>
					<rect x="3.5" y="0.5" width="322" height="661" rx="61.5" stroke="#929098" />
					<rect x="7" y="4" width="315" height="654" rx="58" fill="#18171D" />
					<foreignObject x="0" y="0"  className="phoneVisor_iframe">
						<iframe src="https://ad-genius-ai-react.vercel.app/home"  frameBorder="0" ></iframe>
					</foreignObject>
					<g filter="url(#filter1_i_4_154)">
						<path d="M0 137.6C0 137.04 0 136.76 0.108993 136.546C0.204867 136.358 0.357847 136.205 0.546009 136.109C0.759921 136 1.03995 136 1.6 136H3V166H1.6C1.03995 166 0.759921 166 0.546009 165.891C0.357847 165.795 0.204867 165.642 0.108993 165.454C0 165.24 0 164.96 0 164.4V137.6Z" fill="#929098" />
					</g>
					<g filter="url(#filter2_i_4_154)">
						<path d="M0 199.6C0 199.04 0 198.76 0.108993 198.546C0.204867 198.358 0.357847 198.205 0.546009 198.109C0.759921 198 1.03995 198 1.6 198H3V260H1.6C1.03995 260 0.759921 260 0.546009 259.891C0.357847 259.795 0.204867 259.642 0.108993 259.454C0 259.24 0 258.96 0 258.4V199.6Z" fill="#929098" />
					</g>
					<g filter="url(#filter3_i_4_154)">
						<path d="M0 279.6C0 279.04 0 278.76 0.108993 278.546C0.204867 278.358 0.357847 278.205 0.546009 278.109C0.759921 278 1.03995 278 1.6 278H3V340H1.6C1.03995 340 0.759921 340 0.546009 339.891C0.357847 339.795 0.204867 339.642 0.108993 339.454C0 339.24 0 338.96 0 338.4V279.6Z" fill="#929098" />
					</g>
					<g filter="url(#filter4_i_4_154)">
						<path d="M326 220H327.4C327.96 220 328.24 220 328.454 220.109C328.642 220.205 328.795 220.358 328.891 220.546C329 220.76 329 221.04 329 221.6V318.4C329 318.96 329 319.24 328.891 319.454C328.795 319.642 328.642 319.795 328.454 319.891C328.24 320 327.96 320 327.4 320H326V220Z" fill="#929098" />
					</g>
					<defs>
						<filter id="filter0_i_4_154" x="3" y="0" width="323" height="662" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset />
							<feGaussianBlur stdDeviation="4" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_4_154" />
						</filter>
						<filter id="filter1_i_4_154" x="0" y="136" width="4" height="30" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset dx="1" />
							<feGaussianBlur stdDeviation="1" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_4_154" />
						</filter>
						<filter id="filter2_i_4_154" x="0" y="198" width="4" height="62" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset dx="1" />
							<feGaussianBlur stdDeviation="1" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_4_154" />
						</filter>
						<filter id="filter3_i_4_154" x="0" y="278" width="4" height="62" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset dx="1" />
							<feGaussianBlur stdDeviation="1" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_4_154" />
						</filter>
						<filter id="filter4_i_4_154" x="325" y="220" width="4" height="100" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset dx="-1" />
							<feGaussianBlur stdDeviation="1" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_4_154" />
						</filter>
					</defs>
				</svg>

			</div>
		</div>
	)
}