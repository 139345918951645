import './App.css';
import DefaultLayout from './components/templates/DefaultLayout';

function App() {
  return (
    <div className="App">
        <DefaultLayout/>
    </div>
  );
}

export default App;
