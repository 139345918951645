import { CardContainer } from "../../atoms/containers/ContentContainers";
import { TypeBody1, TypeH2, TypeH6 } from "../../atoms/fonts/Types";
import { IconPlus } from "../../atoms/icons/Icons";
import { StandarButton } from "../../atoms/util/buttons/Buttons";
import "./SectionTwoContent.css"



export function SectionTwoContent() {
    return (
        <div className="sectionTwoContent" data-aos="fade-up" data-aos-duration="3000">
            <div className="sectionTwoContent_column" >
                <TypeH2 className={"-p900-50 "}>Near Shore Design's Commitment to Dignity</TypeH2>
                <TypeBody1 className={"-s50"}>Near Shore Design proudly stands as a Colombian enterprise that is deeply committed to upholding the dignity of IT work within our country. We firmly believe that the IT industry is not just about code and algorithms; it's about the people, their talents, and the positive impact they can have on our nation's technological landscape.</TypeBody1>
                <a href="https://www.linkedin.com/company/near-shore-design-co/" className="Nav_link"> 
                <StandarButton
						icon={<IconPlus  />}
						label={"LINKEDIN"}
						variant={1} />
                </a>
            </div>
            <div className="sectionTwoContent_column">
            <CardContainer>
                    <TypeH6 className={"-p900-50 "}>
                        Investing in Local Talent:
                    </TypeH6>
                    <TypeBody1 className={"-s50"}>
                        One of our core principles is investing in local talent. Colombia boasts a wealth of skilled IT professionals, and Near Shore Design takes pride in providing a platform for these individuals to showcase their expertise. We empower our local workforce, fostering an environment where their skills are nurtured, their creativity is encouraged, and their contributions are celebrated.
                    </TypeBody1>
                </CardContainer>
                <CardContainer>
                    <TypeH6 className={"-p900-50 "}>
                        Ethical and Sustainable Practices
                    </TypeH6>
                    <TypeBody1 className={"-s50"}>
                        We take immense pride in being a "Factory of Development." What does this mean? It means that we are a dedicated and highly efficient production line for transforming ideas into fully-fledged digital products. We work tirelessly to streamline the development process, ensuring that your project is delivered on time, within budget, and to the highest quality standards.
                    </TypeBody1>
                </CardContainer>
            </div>
        </div>
    )
}