import { TypeBody1, TypeH2, TypeH4 } from "../../atoms/fonts/Types";
import { StandarButton } from "../../atoms/util/buttons/Buttons";
import "./SectionFiveContent.css"


export function SectionFiveContent() {
	return (
		<div className="sectionFiveContent" data-aos="fade-up" data-aos-duration="3000">
			<TypeH2 className={"-p900-50"}>
				Contact Us:
			</TypeH2>
			<div className="sectionFiveContent_container">
				<div className="sectionFiveContent_left">
					<TypeH4 className={"-s50"}>
						Do you have
						any questions?
					</TypeH4>
					<TypeBody1 className={"-s50"}>
						Feel free to send us your questions or request a free consultation.
					</TypeBody1>
					<a href="https://wa.link/ou2cni" className="Nav_link">
					<StandarButton
						label={"Send a message"}
						variant={1} />
					</a>
				</div>
				<div className="sectionFiveContent_right">
					<span className="typeH6  -p900" >/</span>
					<TypeBody1 className={"-s50"}>
						We are open for business.
					</TypeBody1>
				</div>
			</div>
		</div>
	)
}
