import { IconAltArrow } from "../../atoms/icons/Icons";
import "./MenuButton.css"

export function MenuButton({setOpen, open}){
 console.log(open)
    return(
        <div   className={`menuButton ${open ? "--up" : "--down"}`} onClick={() => setOpen(!open)}>
            <IconAltArrow className={"-fl-s-50"}/>
        </div>
    )
}