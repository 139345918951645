import { HeroContainer } from "../atoms/containers/ContentContainers";
import heroImage from "../../assets/img/hero-women-touch-key.png"
import { HeroContent } from "../molecules/hero_content/HeroContent";

export function Hero(){
    return (
        <HeroContainer imgRoute={heroImage} id={"hero"}>
            <HeroContent/>
        </HeroContainer>
    )
}