import { CardContainer } from "../../atoms/containers/ContentContainers";
import { TypeBody1, TypeH3, TypeH6 } from "../../atoms/fonts/Types";
import "./SectionTrheeContent.css"

export function SectionTrheeContent() {
	return (
		<div className="sectionTrheeContent" data-aos="fade-up" data-aos-duration="3000">
			<TypeH3 className={"-p900-50 "}>
				Why Choose Near Shore Design?
			</TypeH3>
			<div className="sectionTrheeContent_wrap">
				<CardContainer mod={"-w500"}>
					<TypeH6 className={"-p900-50 "}>
						Expertise
					</TypeH6>
					<TypeBody1 className={"-s50"}>
						Our team comprises highly skilled professionals with years of experience in design and development.
					</TypeBody1>
				</CardContainer>
				<CardContainer mod={"-w500"}>
					<TypeH6 className={"-p900-50 "}>
						Efficiency
					</TypeH6>
					<TypeBody1 className={"-s50"}>
						Our "Factory of Development" approach means faster delivery times without compromising on quality.
					</TypeBody1>
				</CardContainer>
				<CardContainer mod={"-w500"}>
					<TypeH6 className={"-p900-50 "}>
						Innovation
					</TypeH6>
					<TypeBody1 className={"-s50"}>
						We stay ahead of the curve by continuously exploring the latest design trends and emerging technologies.
					</TypeBody1>
				</CardContainer>
				<CardContainer mod={"-w500"}>
					<TypeH6 className={"-p900-50 "}>
						Proven Track Record:
					</TypeH6>
					<TypeBody1 className={"-s50"}>
						Our portfolio showcases a wide range of successful projects across various industries.
					</TypeBody1>
				</CardContainer>
				<CardContainer mod={"-w500"}>
					<TypeH6 className={"-p900-50 "}>
						Collaboration:
					</TypeH6>
					<TypeBody1 className={"-s50"}>
						We value open communication and collaboration with our clients, ensuring your vision is at the forefront of every project.
					</TypeBody1>
				</CardContainer>
			</div>
		</div>
	)
}