import { TypeH1, TypeH2 } from "../../atoms/fonts/Types";
import { IconPlus } from "../../atoms/icons/Icons";
import { StandarButton } from "../../atoms/util/buttons/Buttons";
import "./HeroContent.css"
export function HeroContent() {
	return (
		<div className="heroContent" data-aos="fade-up" data-aos-duration="3000">
			<TypeH1 className={"-p900-50"}>
				Excellence with Innovation
			</TypeH1>
			<div className="heroContent_cta" >
			{/*  
				<div className="heroContent_Buttons">
					<StandarButton
						icon={<IconPlus />}
						label={"EXPLORE"}
						variant={1} />
					<StandarButton
						icon={<IconPlus />}
						label={"EXPLORE"}
						variant={2} />
				</div>
			*/}
				<TypeH2 className={"-s50"}>Professional Design Systems</TypeH2>
			</div>
		</div>
	)

}