import { SectionContainer } from "../atoms/containers/ContentContainers";
import sectionFiveBg from "../../assets/img/Contact-bg.png"
import { SectionFiveContent } from "../molecules/section_five_content/SectionFiveContent";

export function SectionFive(){
    return(
        <SectionContainer imgRoute={sectionFiveBg} id={"five"}>
            <SectionFiveContent/>
        </SectionContainer>
    )
}